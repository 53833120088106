import '../styles/index.scss';

import angular from 'angular';
window.angular = angular;

require('./vendor/SweetAlert');
require('./vendor/sweetalert.min');
require('./vendor/ng-file-upload-shim');
require('./vendor/ng-file-upload');
import './vendor/airlst-sdk_1.1.1.min';

import _ from 'lodash';

angular.module('app', [
  'oitozero.ngSweetAlert',
  'ngFileUpload',
    'AirLST-SDK'
  ])
  .directive('ngEnter', [
    function () {
      return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.vm.loadRsvp();
            });

            event.preventDefault();
          }
        });
      };
    }
  ])

  .controller('AirLSTCtrl', ['$scope', '$http', '$location', 'SweetAlert', 'Upload', 'AirLSTSdkService', '$timeout', '$anchorScroll', '$window', '$filter',  function ($scope, $http, $location, SweetAlert, Upload, AirLSTSdkService, $timeout, $anchorScroll, $window, $filter) {
    var vm = this;

    vm.hasError = false;
    vm.loading = true;
    vm.submitting = false;
    vm.currentView = "login";
    vm.agenda = false;


    vm.roomObj = '';
    vm.room1 = false;
    vm.room2 = false;
    vm.room3 = false;

    vm.showAgenda = function() {
      vm.agenda = !vm.agenda;
    };

    /**
     * Initializes the application and loads all necessary data from the server
     *
     * @private
     */
    vm._init = function () {
      AirLSTSdkService.companyUid = '6N8PUNV';
      AirLSTSdkService.guestlistUid = 'HAW3G9PAOU';
      AirLSTSdkService.apiUrl = 'https://v1.api.airlst.com/lp';

      vm._resetRsvpInformation();
      vm._resetRsvpCode();
      vm._resetAnswerType();
      vm._retrieveEventInformation();
    };

    vm.formValidationReg = false;

    // Public functions

    /**
     * Queries the airlst server for a rsvp
     *
     * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. If the API responds with an rsvp,
     * the 'content' view will be loaded next.
     *
     * If an error occurs during the API call, rsvp information is reset an error message will be displayed.
     *
     * @returns {PromiseLike<T | never> | Promise<T | never> | *}
     */
    vm.isModerator = false;

    vm.loadRsvp = function () {
      vm.loading = true;
      return AirLSTSdkService.retrieveRsvpInformation(vm.rsvpCode).then(function (rsvpInfo) {
        vm.rsvpInformation = vm._prepareRsvpInformationFromApi(rsvpInfo);
        vm.goToView('enter-rooms');

        vm._loadRsvpRooms();

      }, function (error) {
        vm.loading = false;
        vm.hasError = true;
        vm._resetRsvpInformation();

        switch (error.identifier) {
          case 'rsvp_not_found':
            SweetAlert.swal('Code nicht gefunden', 'Ihr Code wurde leider nicht gefunden. Bitte wenden Sie sich an Ihren Ansprechpartner.', 'error');
            break;
          case 're_login_not_allowed':
            SweetAlert.swal('Zugang verweigert!', 'Ihr Code wurde bereits verwendet. Wenn Sie Ihre Anmeldung ändern möchten, wenden Sie sich bitte an Ihren Ansprechpartner.', 'error');
            break;
          default:
            SweetAlert.swal('Error', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte versuchen Sie es erneut.', 'error');
            break;
        }
      }).finally(function () {
        $scope.$applyAsync();

      });
    };

    vm.getRoomLink = function(room) {
      vm.submitting = true;
      $http({
        method: 'POST',
        url: 'https://bbb.micro.customers.airlst.com/api/meetings/join/attendee',
        params: room
      }).then(function successCallback(response) {
        vm.submitting = false;
        window.open(response.data.joinUrl, '_blank');

      }, function errorCallback(response) {
        vm.submitting = false;
        SweetAlert.swal('Error', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte versuchen Sie es erneut.', 'error');
      });
    };

    /**
     * Cancels a rsvp
     *
     * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
     * pass validation.
     *
     * After successful cancellation, the form is reset, a success message is displayed and the 'code' view will
     * be shown next.
     *
     * If an error occurs during the API call an error message is displayed.
     *
     * @param form
     */
    vm.cancelRsvp = function (form) {
      if (form && !vm._validateRegistrationForm(form)) {
        return;
      }

      vm.submitting = true;
      AirLSTSdkService.updateRsvpByCodeAndStatus(vm.rsvpCode, 'cancelled', vm._prepareRsvpInformationForApi(), 'cancelled').then(function (rsvpInfo) {
        vm._init();
        vm.submitting = false;

        // resets the form
        // https://docs.angularjs.org/api/ng/type/form.FormController#$setPristine
        form.$setPristine();

        vm.state = 5;
        vm.answerType = 'cancel';
      }, function (error) {
        vm.hasError = true;
        vm.submitting = false;

        switch (error.identifier) {
          case 'validation':
            SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
            break;
          case 'rsvp_not_found':
          default:
            SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
            break;
        }
      });
    };

    vm.createRsvp = function (form) {
      if (!vm._validateRegistrationForm(form)) {
        return;
      }
      vm.submitting = true;

      if (!vm.rsvpInformation.hasOwnProperty('rsvp')) {
        vm.rsvpInformation.rsvp = {};
      }

      if (!vm.rsvpInformation.hasOwnProperty('contact')) {
        vm.rsvpInformation.contact = {};
      }

      vm.rsvpInformation.rsvp.status = 'requested';

      AirLSTSdkService.submitOpenRequest(vm._prepareRsvpInformationForApi()).then(function (rsvpInfo) {
        form.$setPristine();
        vm._init();
        vm.submitting = false;
        // resets the form
        // https://docs.angularjs.org/api/ng/type/form.FormController#$setPristine
        vm.goToView('done');
        vm.goToRegStep(0);
      }, function (error) {
        vm.hasError = true;
        vm.submitting = false;
      });
    };

    /**
     * Confirms a rsvp
     *
     * vm.rsvpCode has to be set to a valid rsvp code for this call to succeed. Also the supplied form has to
     * pass validation.
     *
     * After successful confirmation, the form is reset, a success message is displayed and the 'code' view will
     * be shown next.
     *
     * If an error occurs during the API call an error message is displayed.
     *
     * @param form
     */
    vm.confirmRsvp = function (form) {
      if (!vm._validateRegistrationForm(form)) {
        return;
      }
      vm.submitting = true;
      AirLSTSdkService.updateRsvpByCodeAndStatus(vm.rsvpCode, 'confirmed', vm._prepareRsvpInformationForApi(), 'confirmed').then(function (rsvpInfo) {
        vm._init();
        vm.submitting = false;

        // resets the form
        // https://docs.angularjs.org/api/ng/type/form.FormController#$setPristine
        form.$setPristine();

        vm.state = 4;
        vm.answerType = 'confirm';
      }, function (error) {
        vm.hasError = true;
        vm.submitting = false;

        switch (error.identifier) {
          case 'validation':
            SweetAlert.swal('Daten fehlerhaft', 'Bitte überprüfen Sie Ihre Daten.', 'error');
            break;
          case 'rsvp_not_found':
          default:
            SweetAlert.swal('Fehler', 'Eine Übertragung ist aus technischen Gründen nicht möglich. Bitte probieren Sie es noch einmal.', 'error');
            break;
        }
      });
    };

    /**
     * Navigates to a different page
     *
     * @param viewToGoTo
     */
    vm.goToView = function (viewToGoTo) {
      vm.currentView = viewToGoTo;
      $scope.$applyAsync();
    };

    /**
     * Sets answer type
     * Is used as a helper for the landingpage to display different views
     *
     * Possible Values: undifined, cancel, confirmed
     * @param newAnswerType
     */
    vm.setAnswerType = function (newAnswerType) {
      vm.answerType = newAnswerType;
    };

    /**
     * Adds an empty contact and rsvp object in the guests array
     */


    vm.addGuest = function () {
      vm.rsvpInformation.guests.push({
        rsvp: {
          status: 'requested'
        },
        contact: {

        }
      });
    };

    /**
     * Deletes the given guest object from the guest array
     *
     * @param guest
     */
    vm.removeGuest = function (guest) {
      var index = vm.rsvpInformation.guests.indexOf(guest);

      vm.rsvpInformation.guests.splice(index, 1);
    };

    // Private internal functions

    /**
     * Checks if the current URL contains the rsvp_code GET parameter and tries to load the rsvp by the
     * supplied parameter
     *
     * @private
     */
    vm._checkRsvpCodeOnUrl = function () {
      var match = $location.absUrl().match(/rsvp_code=([A-Za-z0-9]+)/);

      if (match && match.length >= 2 && match[1]) {
        vm.rsvpCode = match[1];
        vm.loadRsvp();
      } else {
        vm.loading = false;
      }
    };

    /** POinti doc */
    vm._checkRsvpActionOnUrl = function () {
      var match = $location.absUrl().match(/action=([A-Za-z0-9]+)/);

      if (match && match.length >= 2 && match[1]) {
        return match[1];
      } else {
        return false;
      }
    };

    /**
     *
     * Validates the supplied form
     *
     * @param form
     * @returns {boolean}
     * @private
     */
    vm._validateRegistrationForm = function (form) {
      if (!form.$valid) {
        vm.formValidationReg = true;
        SweetAlert.swal('Fehlende Daten', 'Bitte füllen Sie alle Pflichtfelder aus', 'warning');
        return false;
      } else {
        return true;
      }
    };

    /**
     * Populates vm.eventInformation with guestlist data from the server
     *
     * @private
     */
    vm._retrieveEventInformation = function () {
      vm.eventInformation = null;

      AirLSTSdkService.retrieveGuestlistInformation().then(function (eventInformation) {
        vm.eventInformation = eventInformation;
        $scope.$applyAsync();
      }, function () {
        vm.hasError = true;

        SweetAlert.swal('Fehler', 'Die Informationen zum gewünschten Event konnten nicht geladen werden', 'error');
      });
    };

    /**
     * Returns rsvp data ready to be submitted
     *
     * @returns {*|null}
     * @private
     */
    vm._prepareRsvpInformationForApi = function () {

      var data = angular.copy(vm.rsvpInformation);

      return data;
    };

    /**
     * Transforms rsvp data supplied by the API
     *
     * @param inData
     * @returns {*}
     * @private
     */

    vm._prepareRsvpInformationFromApi = function (inData) {
      inData.contact.sex = inData.contact.sex ? inData.contact.sex : null;
      return inData;
    };

    vm.checkAvailableRooms = function() {
      angular.forEach(vm.rooms, function(value, key) {
        if(value.room.uuid == '02613927-b76a-47cf-925c-82c1524e87dd') {
          vm.room1 = true;
        }
        if(value.room.uuid == '1211036b-ee3d-4432-996c-ca5aba7091a0') {
          vm.room2 = true;
        }
        if(value.room.uuid == 'a560efea-d28f-4193-afbb-fc30997442a3') {
          vm.room3 = true;
        }
      });
    };

    vm._loadRsvpRooms = function () {
      if(!vm.rsvpCode) {
        return;
      }
      $http.get(AirLSTSdkService.apiUrl + '/guestlists/' + AirLSTSdkService.guestlistUid + '/rsvps/' + vm.rsvpCode + '/rooms', {
        headers: {
          'x-airlst-company': AirLSTSdkService.companyUid
        }
      })
          .then((response) => {
            vm.rooms = response.data.data;
            vm.checkAvailableRooms();
            $scope.$applyAsync();
          });

      $timeout(() => {
        vm._loadRsvpRooms();
      }, 10000);
    };

    vm.joinRoom = function (room) {
      vm.roomObj =  $filter('filter')(vm.rooms, {'room': {'uuid':room }});
      const newWindow = window.open('', '_blank');

      $http.post(
          AirLSTSdkService.apiUrl + '/guestlists/' + AirLSTSdkService.guestlistUid + '/rsvps/' + vm.rsvpCode + '/rooms/' + vm.roomObj[0].room.uuid + '/join',
          {},
          {
            headers: {
              'x-airlst-company': AirLSTSdkService.companyUid
            }
          }
      )
          .then((response) => {
            newWindow.location = response.data.data.join_url;
          });
    };

    vm._resetRsvpInformation = function () {
      vm.rsvpInformation = null;
    };

    vm._resetRsvpCode = function () {
      vm.rsvpCode = '';
    };

    vm._resetAnswerType = function () {
      vm.answerType = 'undefined';
    };

    vm._init();
    vm._checkRsvpCodeOnUrl();
  }]);
